/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import LogoFractal from '@/images/img/logotipo-fractal-black.svg'

import { 
  demandasContent,
  boxContent,
}  from '../assets/styles/Other.styles'

const DemandasComunicacao = () => {
  return (
    <Fragment>
      <section css={demandasContent}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="text-center">
                <img
                  src={LogoFractal}
                  alt='Fractal - Reinventing Businesses'
                  title='Fractal - Reinventing Businesses'
                  style={{ width: 260, marginBottom: 32 }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <a href="/demandascomunicacao" style={{ fontSize: 20, marginBottom: 0, textDecoration: 'underline' }}>Ínicio</a>
                <p style={{ fontSize: 20, color: '#776AAE' }}>/ Sites</p>
              </div>
            </Col>
          </Row>
          <div css={boxContent}>
            <Row>
              <Col lg={2} md={2} sm={12} />
              <Col lg={8} md={8} sm={12}>
                <iframe src="https://forms.monday.com/forms/embed/76f61547b4be731f2b44ce74af3263ce?r=use1" style={{ width: "100%", height: 3100, border: 0 }} />
              </Col>
              <Col lg={2} md={2} sm={12} />
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default DemandasComunicacao;
