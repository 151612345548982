import React from "react";
import Layout from "@/components/layout";
import ContentSucesso from "@/components/SitesForm";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";

const Sites = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Solicitação para Sites">
          <ContentSucesso />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Sites;
